<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>企业管理</a-breadcrumb-item>
            <a-breadcrumb-item>企业统计</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-box">
            <div class="clearfix table-tools">
            <div class="buttons">
            </div>
            <div class="search">
                <a-form layout='inline'>
                <a-form-item>
                <a-radio-group :value="paramsData.filter_type" @change="handleButChange">
                    <a-radio-button value="day">
                    日
                    </a-radio-button>
                    <a-radio-button value="week">
                    周
                    </a-radio-button>
                    <a-radio-button value="month">
                    月
                    </a-radio-button>
                    <a-radio-button value="year">
                    年
                    </a-radio-button>
                </a-radio-group>
                </a-form-item>
                <a-form-item>
                    <a-tooltip placement="topLeft" >
                    <template slot="title">
                        <span>时间筛选</span>
                    </template>
                    <a-range-picker
                        :ranges="rangesData"
                        :value='rangesValue'
                        :disabled-date="disabledDate" :allowClear="false"
                        :default-value="[moment(paramsData.start_date,'YYYY-MM-DD'),moment(paramsData.end_date,'YYYY-MM-DD')]"  style="width: 240px"
                        @change="(date, dateString)=>onChange(date, dateString,1)" />
                    </a-tooltip>
                </a-form-item>
                <a-form-item>
                    <a-select style="width:160px" v-model='paramsData.wid' @search="handleSearch" allowClear showSearch placeholder="请选择应用" :filterOption="filterOption">
                    <a-select-option v-for="(d, index) of appList" :key="index" :value="d['wid']">{{ d['app_name'] }}</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item>
                    <a-button @click="toScreen" type="primary">筛选</a-button>
                </a-form-item>
                </a-form>
            </div>
            </div>
            <div class="dashboard bk-group">
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <statisticsCount :count='counts' :col="6"/>
            <a-row class="mb16" :gutter="16">
                <a-col :lg="24" :xl="24">
                    <statisticsLine :item='modalData' title="企业统计" :loading="loading" :start_date='paramsData.start_date' :end_date='paramsData.end_date'/>
                </a-col>
                <a-col :lg="24" :xl="24">
                    <statisticsPayment :loading='loadingP' title="交易金额"  :leads_data ='paymentDate' :start_date='paramsData.start_date' :end_date='paramsData.end_date'/>
                </a-col>

                <a-col :lg="24" :xl="24">
                    <statisticsPayment :loading='loadingBD' title="上课学员"  :leads_data ='bookData' :start_date='paramsData.start_date' :end_date='paramsData.end_date'/>
                </a-col>

                <a-col :lg="24" :xl="24">
                    <statisticsPayment :loading='loadingNo' title="推送统计"  :leads_data ='notificationData' :start_date='paramsData.start_date' :end_date='paramsData.end_date'/>
                </a-col>

                <a-col :lg="24" :xl="12">
                    <LRanking title="金额统计" :rank_data="bankTypeCount" :loading="loadingBT" :start_date='paramsData.start_date' :end_date='paramsData.end_date' />    
                </a-col>

                <a-col :lg="24" :xl="12">
                    <LRanking title="金额统计" :rank_data="bankTypeSummate" :loading="loadingBT" :start_date='paramsData.start_date' :end_date='paramsData.end_date' />    
                </a-col>

                <a-col :lg="24" :xl="12">
                    <LRanking title="交易金额" :rank_data="financeSummateDate" :loading="loadingBT" :start_date='paramsData.start_date' :end_date='paramsData.end_date' />    
                </a-col>

                <a-col :lg="24" :xl="12">
                    <LRanking title="交易次数" :rank_data="financeCountDate" :loading="loadingBT" :start_date='paramsData.start_date' :end_date='paramsData.end_date' />    
                </a-col>
                

                <a-col :lg="24" :xl="12">
                    <VErings :loading='loadingBT' :item ='bankTypeCount' title="金额统计" subtext='' />
                </a-col>
                <a-col :lg="24" :xl="12">
                    <VErings :loading='loadingBT' :item ='bankTypeSummate' title="金额统计" subtext='' />
                </a-col>
                
                
                <a-col :lg="24" :xl="24">
                    <dailyActive title="日活跃" :loading='loadingDA'  :leads_data ='incomeDate' :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                </a-col>

                <a-col :lg="24" :xl="24">
                      <userHeatmap :loading="loadingUH" :item="heatmapUhData" title="H5热点图" :start_date='paramsData.start_date' :end_date='paramsData.end_date'  />
                </a-col>

                <a-col :lg="24" :xl="24">
                    <employeeHeatmap :loading="loadingEH" :item="heatmapEhData" title="Web热点图" :start_date='paramsData.start_date' :end_date='paramsData.end_date'  />
                </a-col>

            </a-row>
            </div>
        </div>
    </div>
</template>

<script>
import userHeatmap from './userHeatmap'
import employeeHeatmap from './employeeHeatmap'
import ranges from "@/common/mixins/ranges"
import moment from 'moment'
import statisticsLine from './statisticsLine'
import dailyActive from './dailyActive'
import statisticsPayment from './leads_follow_count'
import statisticsCount from '@/views/bi/ranking/money/statistics'
    export default {
        name:'statistics',
        data() {
            return {
                loading:false,
                loadingP:false,
                loadingF:false,
                loadingDA:false,
                loadingBT:false,
                loadingBD:false,
                loadingUH:false,
                loadingEH:false,
                start_date:'',
                end_date:'',
                paramsData:{
                    filter_type:'day',
                    start_date:'',
                    end_date:'',
                },
                modalData:{},
                paymentDate:{},
                incomeDate:{},
                financeCountDate:{},
                financeSummateDate:{},
                bankTypeCount:{},
                bankTypeSummate:{},
                heatmapUhData:{},
                heatmapEhData:{},
                bookData:[],
                notificationData:[],
                counts:[],
                appList:[]
            }
        },
        mixins: [ranges],
        components:{statisticsLine,statisticsCount,dailyActive,statisticsPayment,userHeatmap,employeeHeatmap},
        created () {
            this.paramsData.start_date = moment().subtract('days',30).format('YYYY-MM-DD')
            this.paramsData.end_date = moment().subtract('days',0).format('YYYY-MM-DD')
            this.rangesValue = [moment(this.paramsData.start_date,'YYYY-MM-DD'),moment(this.paramsData.end_date,'YYYY-MM-DD')]
            this.getCount()
            this.getApp()
            this.getList()
            this.getPayment()
            this.getBook()
            this.getNotification()
            this.getFinance()
            this.getBankType()
            this.getDailyActive()
            this.getUserHeatmap()
            this.getEmployeeHeatmap()
        },
        methods: {
            filterOption(input, option) {
                return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            async getList() {
                this.loading = true
                let res = await this.$store.dispatch('enterpriseStatisticsAction', {data:{search:this.paramsData}})
                this.modalData= res.data
                this.loading = false
            },
            async getCount() {
                let res = await this.$store.dispatch('enterpriseStatisticsCountAction')
                this.counts= res.data
            },
            async getPayment(){
                this.loadingP = true
                let res = await this.$store.dispatch('enterpriseStatisticsPaymentAction',{data:{search:this.paramsData}})
                this.paymentDate= res.data
                this.loadingP = false
            },
            async getDailyActive(){
                this.loadingDA = true
                let res = await this.$store.dispatch('enterpriseStatisticsDailyActiveAction',{data:{search:this.paramsData}})
                this.incomeDate= res.data
                this.loadingDA = false
            },
            async getBankType() {
                this.loadingBT = true
                let res = await this.$store.dispatch('enterpriseStatisticsBankTypeAction', {data:{search:this.paramsData}})
                this.bankTypeCount= res.data.count
                this.bankTypeSummate= res.data.summate
                this.loadingBT = false
            },
            async getFinance(){
                this.loadingF = true
                let res = await this.$store.dispatch('enterpriseStatisticsFinanceAction',{data:{search:this.paramsData}})
                this.financeCountDate= res.data.count
                this.financeSummateDate= res.data.summate
                this.loadingF = false
            },
            async getBook(){
                this.loadingBD = true
                let res = await this.$store.dispatch('enterpriseStatisticsBookAction',{data:{search:this.paramsData}})
                this.bookData= res.data
                this.loadingBD = false
            },
            async getNotification(){
                this.loadingNo = true
                let res = await this.$store.dispatch('enterpriseStatisticsNotificationAction',{data:{search:this.paramsData}})
                this.notificationData= res.data
                this.loadingNo = false
            },
            async getApp(val){
                await this.$store.dispatch('enterpriseFilterAppAction', {data:{app_name:val}})
                .then((res)=>{
                    this.appList = res.data
                })
            },
            async getUserHeatmap(){
                this.loadingUH = true
                let res = await this.$store.dispatch('enterpriseStatisticsDailyUserHeatmapAction',{data:{search:this.paramsData}})
                this.heatmapUhData= res.data
                this.loadingUH = false
            },
            async getEmployeeHeatmap(){
                this.loadingEH = true
                let res = await this.$store.dispatch('enterpriseStatisticsDailyEmployeeHeatmapAction',{data:{search:this.paramsData}})
                this.heatmapEhData= res.data
                this.loadingEH = false
            },
            handleButChange(e){
                this.paramsData.filter_type = e.target.value
                this.toScreen()
            },
            toScreen(){
                this.getList()
                this.getPayment()
                this.getBook()
                this.getFinance()
                this.getBankType()
                this.getDailyActive()
                this.getUserHeatmap()
                this.getEmployeeHeatmap()
            },
            handleSearch(val){
                this.getApp(val)
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>