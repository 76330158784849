<template>
   <a-card title="">
        <div>
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <ve-histogram 
                :data="leads_data" 
                :height="heightStr"
                :legend="{bottom:10,show:true}"
                judge-width
                :grid="{right: '1%'}"
                :mark-line="markLine"
                :title="{
                    text:`${title}（${leads_data.title?leads_data.title:'0'}）`,
                    subtext: start_date==end_date?`${start_date}`:`${start_date} ~ ${end_date}`,
                    textStyle: {
                        fontSize: 15,
                    },
                    subtextStyle: {
                        fontSize: 12.5
                    }
                }"
                :toolbox="toolbox"
                :settings="{
                    label: { show: true, position: 'top'},
                    yAxisType: ['KMB', 'KMB'],
                    axisSite: { right: ['H5','H5企业微信','小程序','企业小程序'] }, 
                    showLine: ['H5','H5企业微信','小程序','企业小程序']
                }" 
                :extend="histogramChartExtend"></ve-histogram>
            <LEmpty v-if="first>0 && leads_data.rows.length == 0"/>
            </div>
    </a-card>
</template>

<script>
    export default {
        name:"dailyActive",
        data () {
            this.markLine = {
                symbol: ['none', 'none'], //去掉箭头
                data: [
                {
                    name: '平均线',
                    type: 'average'
                }
                ],
                label:{
                    position:'insideEndTop'
                }
            },
            this.toolbox = {
                feature: {
                magicType: {},
                saveAsImage: {pixelRatio:2}
                }
            }
            return{
                first:0,
                histogramChartExtend:{
                    series(item){
                        if(!item) return false
                        item[0].barMaxWidth = 10
                        item[0].itemStyle = Object.assign({}, {
                            normal:{
                                color: function(params) {
                                    return {
                                        type: 'linear',
                                        x:1,
                                        y:1,
                                        x2:0,
                                        y2:0,
                                        colorStops: [{
                                            offset: 0,
                                            color: '#ffffff'
                                            
                                        }, {
                                            offset: 1,                    
                                            color: '#23d9ae'
                                        }],
                                    }
                                },
                                barBorderRadius:[30,30,0,0]
                            }
                        })
                        return item
                    },
                    xAxis:{
                        axisLine:{
                            show:true,
                            lineStyle: {
                                type: 'dotted',
                            }
                        },
                    },
                    yAxis(item){
                        item[0].axisLine = Object.assign({}, {
                            show:false
                        })
                        item[1].axisLine = Object.assign({}, {
                            show:false
                        })
                        item[0].splitLine = Object.assign({}, {
                            show:true,
                            lineStyle: {
                                type: 'dotted'
                            }
                        })
                        item[1].splitLine = Object.assign({}, {
                            show:false,
                            lineStyle: {
                                type: 'dotted'
                            }
                        })
                        return item
                    },
                }
            }
        },
        props:['leads_data' , 'loading','start_date','end_date', 'heightStr','title'],
        watch: {
            loading(newValue, oldValue) {
                this.first++
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>